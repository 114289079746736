<div class="tags-container" #tagsContainer>
  <div class="tags-section">
    <div
      *ngFor="let selectedTag of selectedTags; let i = index"
      class="selcted-tags"
      [ngStyle]="{
        background: selectedTag.backgroundColour,
        color: selectedTag.textColour
      }"
    >
      <span>{{ selectedTag.value }}</span>
      <button class="close-btn" type="button" (click)="removeTag(selectedTag)">
        <span [ngStyle]="{color: selectedTag.textColour, fill: selectedTag.textColour}" [inlineSVG]="'assets/icons/close.svg'"></span>
      </button>
    </div>
  </div>
  <tg-dropdown
    [renderInPlace]="true"
    [inlineLabel]="false"
    [placeholder]="placeholder"
    [horizontalPosition]="'left'"
    (onOpen)="handleDDOpen()"
  >
    <ul class="tg-dropdown-list" *ngIf="!!filteredDropDownContent.length;else noItems">
      <li *ngFor="let option of filteredDropDownContent">
        <a (click)="onSelect(option)"> {{ option.value }}</a>
      </li>
    </ul>
    <ng-template #noItems>
      <span class="no-items-message">{{ noItemsMessage }}</span>
    </ng-template>
  </tg-dropdown>
</div>
