import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { catchError, defer, finalize, Observable, of, throwError } from 'rxjs';
import { HttpRequest, HttpRequestHooks } from './models/http-request';
import { environment } from 'src/environments/environment';

export interface IHttpClientService {
  postReq<T>(httpInput: HttpRequest): Observable<T>;
  putReq<T>(httpInput: HttpRequest): Observable<T>;
  getReq<T>(httpInput: HttpRequest): Observable<T>;
  downloadMedia(
    url: string,
    headers?: HttpHeaders | { [header: string]: string | string[] }
  ): Observable<HttpResponse<Blob>>;
}

export class HttpClientService implements IHttpClientService {

  constructor(
    private httpClient: HttpClient,
    private BaseURL: string,
    private defaultHooks: HttpRequestHooks
  ) {
  }

  /**
   * setting up Http request.
   * @param httpInput http input
   * @param req req to be modified
   * @returns returns new request.
   */
  private setupRequest<T>(httpInput: HttpRequest, req: Observable<T>) {
    const onRequesStarttHook = httpInput?.hooks?.onRequestStart || this.defaultHooks?.onRequestStart;

    if (!!onRequesStarttHook) {
      //TODO: This can give a side effect as this should start actually when user attaches subscription.
      onRequesStarttHook();
    }

    const onErrorHook = httpInput?.hooks?.onError || this.defaultHooks?.onError;

    if (!!onErrorHook) {
      req = req.pipe(catchError(err => {
        onErrorHook(err);
        return of({} as T);
      }));
    }

    const onRequestCompleteHook = httpInput?.hooks?.onRequestComplete || this.defaultHooks?.onRequestComplete;

    if (!!onRequestCompleteHook) {
      req = req.pipe(finalize(() => {
        onRequestCompleteHook();
      }));
    }
    return req;
  }

  /**
   * Http post request
   * @param httpInput Http input
   * @returns Observable
   */
  public postReq<T>(httpInput: HttpRequest): Observable<T> {
    const postUrl = new URL(`api/${httpInput.url}`, this.BaseURL);
    let req = this.httpClient
      .post<T>(postUrl.toString(), httpInput.data, {
        headers: httpInput.headers,
      });

    req = this.setupRequest<T>(httpInput, req);
    return req;
  }


  /**
   * Http put request
   * @param httpInput Http input
   * @returns
   */
  public putReq<T>(httpInput: HttpRequest): Observable<T> {
    const putUrl = new URL(`api/${httpInput.url}`, this.BaseURL);
    let req = this.httpClient
      .put<T>(putUrl.toString(), httpInput.data, {
        headers: httpInput.headers,
      });

    req = this.setupRequest(httpInput, req);
    return req;
  }

  /**
   * Http get request
   * @param httpInput Http input
   * @returns
   */
  public getReq<T>(httpInput: HttpRequest): Observable<T> {
    const getUrl = new URL(`api/${httpInput.url}`, this.BaseURL);
    let req = this.httpClient
      .get<T>(getUrl.toString(), { headers: httpInput.headers });

    req = this.setupRequest(httpInput, req);
    return req;
  }

  /**
   * Http download request
   * @param url - download Url
   * @param headers - headers
   * @returns Observable
   */
  public downloadMedia(
    url: string,
    headers?: HttpHeaders | { [header: string]: string | string[] }
  ): Observable<HttpResponse<Blob>> {
    const downloadUrl = new URL(`api/${url}`, this.BaseURL);
    return this.httpClient.get<Blob>(downloadUrl.toString(), {
      observe: 'response',
      responseType: 'blob' as 'json',
      headers: headers,
    });
  }
}
