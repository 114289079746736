import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'wharm-timer',
  templateUrl: './timer.component.html',
  styleUrls: ['./timer.component.scss']
})
export class TimerComponent implements OnChanges {
  private readonly ChatConversationTimerLimitInMinutes = environment.chatConversationTimer.limitInMinutes;
  private readonly progressBarZones = environment.chatConversationTimer.progressBarZonesLimit;
  private currentValue: moment.Moment;
  private intervalId: NodeJS.Timeout;
  private totalSecondsLeft = 0;

  @Input() value: Date;
  @Input() showTimerIcon = false;
  @Input() showCircularProgress = false;

  hours = 0;
  minutes = 0;
  progressBarColor = 'var(--primary)';

  constructor() { }
  
  ngOnChanges(changes: SimpleChanges): void {
    this.value = new Date(this.value);
    this.progressBarColor = 'var(--primary)';
    if(changes['value'])
    {
      if(this.intervalId){
        clearInterval(this.intervalId);
      }

      this.initiateValue();
  
      if((this.totalSecondsLeft/60) > 0){
        this.initiateCountdown();
      }
    }
  }

  resetValues() {
    this.currentValue = null;
    this.totalSecondsLeft = 0;
    this.hours = 0;
    this.minutes = 0;
  }
  
  initiateCountdown() {
    this.intervalId = setInterval(() => {
      this.totalSecondsLeft = this.currentValue.diff(moment.utc(),'seconds');
      this.calculateTimeLeft();
    }, 1000);
  }
    
  initiateValue() {
    this.currentValue = moment(this.value.toUTCString());
    if(this.currentValue)
    {
      this.totalSecondsLeft = this.currentValue.diff(moment.utc(),'seconds');
      this.calculateTimeLeft();
    }
  }
    
  calculateTimeLeft() {
    if((this.totalSecondsLeft/60) <= 0) // neglecting seconds left
    {
      this.resetValues();
      if(this.intervalId){
        clearInterval(this.intervalId);
      }
      return;
    }
    this.hours = Math.floor(this.totalSecondsLeft/3600);
    this.minutes = Math.floor(this.totalSecondsLeft/60)%60;
  }

  calculateTimerOffset(strokeSize: number) {
    const totalMinutesLeft = Math.floor(this.totalSecondsLeft/60);
    const percentageOfMinutesLeft=(totalMinutesLeft/this.ChatConversationTimerLimitInMinutes)*100;
    if(percentageOfMinutesLeft < this.progressBarZones.danger.percentage ){
      this.progressBarColor = this.progressBarZones.danger.color;
    }
    else if(percentageOfMinutesLeft < this.progressBarZones.warning.percentage){
      this.progressBarColor = this.progressBarZones.warning.color;
    }
    return strokeSize - (strokeSize * percentageOfMinutesLeft) / 100 ;
  }

}
