import { Injectable } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Injectable({
  providedIn: 'root'
})
export class WharmLoaderService {
  constructor(private ngxService: NgxUiLoaderService) {
  }
  start(loaderId:string=null) {
    if(!!(loaderId?.trim())){
      this.ngxService.startLoader(loaderId);
    }else{
      this.ngxService.start();
    }
  }
  stop(loaderId:string=null) {
    if(!!(loaderId?.trim())){
      this.ngxService.stopLoader(loaderId);
    }else{
      this.ngxService.stop();
    }
  }
}
