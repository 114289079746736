import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { filter, map } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { AuthResponseStatus } from './core/services/authResponseStatus';
import { AuthenticationService } from './core/services/authentication.service';
import { WharmToasterService } from './shared/services/wharm-toaster.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  private authResponseSubscription: Subscription;
  public myTitle = 'WharmUI';

  constructor(
    private router: Router,
    private title: Title,
    private _authService: AuthenticationService,
    private _toasterService: WharmToasterService
  ) {}

  
  //#region private
  private authResponseStatusSubscriptions() {
    this.authResponseSubscription =
      this._authService.authResponseStatusObs$.subscribe((value) => {
        if (!value.isSuccess) {
          this._toasterService.errorMessage(
            'Authentication Error',
            value.message
          );
          this._authService.logout();
        }
      });
  }

  private subscribeRouterEvents() {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.router)
      )
      .subscribe((event) => {
        const title = this.getTitle(
          this.router.routerState,
          this.router.routerState.root
        ).join(' | ');
        this.title.setTitle(title);
      });
  }
  private getTitle(state: any, parent: any): any[] {
    const data = [];
    if (parent && parent.snapshot.data && parent.snapshot.data['title']) {
      data.push(parent.snapshot.data['title']);
    }
    if (state && parent) {
      data.push(...this.getTitle(state, state.firstChild(parent)));
    }
    return data;
  }
  //#endregion

  ngOnDestroy(): void {
    this.authResponseSubscription?.unsubscribe();
  }

  ngOnInit(): void {
    this.subscribeRouterEvents();
    this.authResponseStatusSubscriptions();
  }
}
