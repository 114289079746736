import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClientService, IHttpClientService } from './http-client.service';
import { HttpClientServiceConfig } from './models/http-client-service-config.model';

/**
 * Http client factory to create Http service.
 */
@Injectable({
  providedIn: 'root',
})
export class HttpClientServiceFactory {
  constructor(private http: HttpClient) { }

  /**
   * Create http client based on serviceKey
   * @param serviceKey - identifier for the service configuration present in environment file.
   * @returns - IHttpClientService
   */
  public createHttpClientService(httpClientServiceConfig: HttpClientServiceConfig): IHttpClientService {

    const config = environment.serviceConfig.find((res) => res.key === httpClientServiceConfig.serviceKey);
    if (!config) {
      throw new Error(`No http service found with provided name:${httpClientServiceConfig.serviceKey}`);
    }
    return new HttpClientService(this.http, config.baseURL, httpClientServiceConfig.defaultHooks);
  }
}
