<div class="accordion-container">
  <div class="accordion-wrapper">
      <tg-accordion>
        <div header class="inner-accordion-header">
          <tg-checkbox *ngIf="!!node.checkStatus" (change)="toggleNode($event, node)" [ngModel]="node.checkStatus === eCheckStatus.Checked"
            [partialCheck]="node.checkStatus === eCheckStatus.PartiallyChecked">
          </tg-checkbox>
          {{node.name}}
        </div>
        <div content class="inner-accordion-content" >
            <div *ngIf="!!endNodes && endNodes.length" class="end-nodes">
              <ng-container *ngFor="let node of endNodes; let i = index;" >
                <tg-checkbox [label]="node.name" [ngModel]="node.checkStatus === eCheckStatus.Checked"
                  (change)="toggleNode($event, node)"
                  ></tg-checkbox>
              </ng-container>
            </div>
            <ng-template [ngIf]="!!childrenNodes && childrenNodes.length">
              <ng-container *ngFor="let node of childrenNodes">
                  <wharm-node [node]="node" (onChildNodeCheckStatusChange)="onChildNodeCheckStatusChangeFunc($event)" [treeMetaData]="treeMetaData"></wharm-node>
              </ng-container>
            </ng-template>

        </div>
      </tg-accordion>
    </div>
  </div>
