export const ServiceKeys = {
  IdentityService: 'IdentityService',
  BFF: 'BFF'
};
export const EndPoints = {
  GET_CONTACTS: 'ApplicationContact/GetContacts',
  GET_CONTACT: 'ApplicationContact/GetContact',
  ADD_CONTACT: 'ApplicationContact/AddContact',
  EDIT_CONTACT: 'ApplicationContact/UpdateContact',
  UPDATE_STATUS: 'ApplicationContact/UpdateAppContactStatus',
  ASSIGN_DEASSIGN_GROUP_CONTACT: 'ApplicationContact/AssignDeAssignContactGroups',
  ADD_NOTE: 'ApplicationContact/AddNote',
  UPDATE_NOTE: 'ApplicationContact/UpdateNote',
  DELETE_NOTE: 'ApplicationContact/DeleteNote',
  APPLICATION_CONTACT_TAGS_ASSIGNMENT: 'ApplicationContact/ApplicationContactTagsAssignment',
  GET_COUNTRIES: 'Metadata/GetCountries',
  GET_STATES: 'Metadata/GetStates',
  GET_APP_PRIVILEGES: 'Metadata/GetPrivileges',
  GET_USERS: 'Application/ApplicationUsers',
  GET_GROUPS: 'Application/ApplicationContactGroups',
  ADD_GROUPS: 'Application/AddApplicationContactGroups',
  CREATE_TAG: 'Application/CreateTag',
  GET_TAGS: 'Application/GetTags/',
  UPDATE_TAG: 'Application/UpdateTag/',
  DELETE_TAG: 'Application/DeleteTag/',
  ASSIGN_DEASSIGN_USER_CONTACT: 'ApplicationUserContact/AssignDeAssignUserContact',
  LOGIN: 'Identity/Login',
  LOGOUT: 'Identity/Logout',
  REFRESH_TOKEN: 'Identity/AccessToken',
  GET_USER: 'User/GetUser/',
  ACCOUNT_ACTIVATION: 'Account/AccountActivation',
  ACCOUNT_REGISTER: 'Account/Register',
  ACCOUNT_INITIATEACTIVATION: 'Account/InitiateAccountActivation',
  FORGOT_PASSWORD: 'Account/ForgotPassword',
  CHANGE_PASSWORD: 'Account/ChangePassword',
  INITIATE_CHANGE_EMAIL: 'Account/InitiateChangeEmail',
  VERIFY_EMAIL_CHANGE: 'Account/VerifyChangeEmail',
  INITIATE_FORGOT_PASSWORD: 'Account/InitiateForgotPassword',
  // GET_APPLICATION_DYNAMIC_CONTACT_FIELDS: 'DynamicSection/GetSections/1',
  GET_APPLICATION_DYNAMIC_CONTACT_FIELDS: 'DynamicUI/GetPages/1',
  SEND_MESSAGE: 'Message/send-message',
  UPLOAD_MEDIA: 'Media/upload',
  DOWNLOAD_MEDIA: 'Media/download/',
  GET_APP_CONTACTS: 'ApplicationContact/GetUserAssignedContacts/',
  GET_CONTACTS_CHAT_STATUS: 'Chat/GetContactsChatStatus',
  GET_APP_CHAT_CONTACTS: 'ApplicationContact/GetAppContacts',
  GET_CHAT_HISTORY: 'Chat/GetChatHistory',
  GET_CONTACT_CHAT_WINDOW_EXPIRY: 'Chat/GetContactChatWindowExpiry',
  UPDATE_MESSAGE_STATUS: 'Message/markChat-as-read',
  GET_TEMPLATES: 'Template/get-templates',
  GET_TEMPLATE: 'Template/get-template/',
  GET_APPUSERS: 'User/GetAppUsers',
  UPDATE_APPUSERSTATUS: 'User/UpdateAppUsersStatus',
  GET_APP_ROLES: 'Roles/GetAppRoles',
  CREATE_APP_ROLE: 'Roles/CreateRole',
  UPDATE_APP_ROLE: 'Roles/UpdateRole',
  DELETE_APP_ROLE: 'Roles/DeleteRole',
  GET_APP_ROLE_PRIVILEGES: 'Roles/GetAppRolePrivileges',
  ROLE_PRIVILEGES_ASSIGNMENT: 'User/RolePrivilegeAssignment',
  CREATE_APPUSER: 'User/CreateUser',
  UPDATE_APPUSER: 'User/UpdateAppUser',
  GET_IS_WHATSAPP_NUMBER: 'Message/IsWhatsAppNumber',
  GET_CONTACTS_REPORT: 'Reports/GetContactsReport',
  GET_USERS_REPORT: 'Reports/GetUsersReport',
  GET_CHATS_REPORT: 'Reports/GetChatsReport'
};

export const BFFEndpoints = {
  GET_APPLICATION_DYNAMIC_CONTACT_FIELDS: 'AppData/GetContactDynamicPages',

  GET_CONTACTS_REPORT: 'Dashboard/ContactsReport',
  GET_USERS_REPORT: 'Dashboard/UsersReport',
  Get_CHATS_REPORT: 'Dashboard/ChatsReport',

  GET_COUNTRIES: 'AppData/GetCountries',
  GET_STATES: 'AppData/GetStates',
  GET_APP_PRIVILEGES: 'AppData/GetPrivileges',
  Get_APP_CONTACT_DYNAMIC_FIELDS: 'AppData/ContactsDynamicSections',
  CREATE_APP_ROLE: 'AdminSetup/RolesAndPermissions/CreateRole',
  UPDATE_APP_ROLE: 'AdminSetup/RolesAndPermissions/UpdateRole',
  DELETE_APP_ROLE: 'AdminSetup/RolesAndPermissions/DeleteRole',
  GET_APP_ROLES: 'AdminSetup/RolesAndPermissions/GetAppRoles',
  GET_APP_ROLE_PRIVILEGES: 'AdminSetup/RolesAndPermissions/GetAppRolePrivileges',

  GET_USER: 'Users/GetUser/',
  CREATE_USER: 'Users/CreateUser',
  GET_ACTIVE_USERS: 'Users/GetAppActiveUsers',
  GET_INACTIVE_USERS: 'Users/GetAppInActiveUsers',
  UPDATE_ACTIVE_USERS: 'Users/UpdateAppActiveUser',
  UPDATE_INACTIVE_USERS: 'Users/UpdateAppInActiveUser',
  UPDATE_ACTIVE_USERS_STATUS: 'Users/UpdateAppActiveUsersStatus',
  UPDATE_INACTIVE_USERS_STATUS: 'Users/UpdateAppInActiveUsersStatus',
  ROLE_PRIVILEGES_ASSIGNMENT: 'Users/RolePrivilegesAssignment',
  ASSIGN_DEASSIGN_CONTACTS: 'Users/AssignDeAssignContacts',
  GET_APP_USERS: 'Users/GetAppUsers',

  GET_ACTIVE_CONTACTS: 'Contacts/GetActiveContacts',
  GET_INACTIVE_CONTACTS: 'Contacts/GetInActiveContacts',
  ADD_CONTACT: 'Contacts/AddContact',
  ADD_CONTACTS: 'Contacts/AddContacts',
  UPDATE_ACTIVE_CONTACT: 'Contacts/UpdateActiveContact',
  UPDATE_INACTIVE_CONTACT: 'Contacts/UpdateInActiveContact',
  CONTACT_EXIST: 'Contacts/ContactExist/',
  UPDATE_ACTIVE_CONTACT_STATUS: 'Contacts/UpdateActiveAppContactStatus',
  UPDATE_INACTIVE_CONTACT_STATUS: 'Contacts/UpdateInactiveAppContactStatus',
  GET_CONTACT: 'Contacts/GetContact',
  ASSIGN_DEASSIGN_CONTACT_GROUPS: 'Contacts/AssignDeAssignContactGroups',
  GET_CONTACTS: 'Contacts/GetAppContacts/',
  GET_USER_ASSIGNED_CONTACTS: 'Contacts/GetUserAssignedContacts',
  ADD_NOTE: 'Contacts/AddNote',
  UPDATE_NOTE: 'Contacts/UpdateNote',
  DELETE_NOTE: 'Contacts/DeleteNote/',
  GET_TAGS: 'AppData/GetTags/',
  APPCONTACT_TAGS_ASSIGNMENT: 'Contacts/ApplicationContactTagsAssignment',
  ASSIGN_DEASSIGN_USERS: 'Contacts/AssignDeAssignUsers',
  GET_CONTACT_GROUPS: 'Contacts/GetApplicationContactGroups',
  ADD_CONTACT_GROUPS: 'Contacts/AddApplicationContactGroups',

  GET_IS_WHATSAPP_NUMBER: 'Contacts/IsWhatsAppNumber',
  GET_APP_CONTACTS: 'Contacts/GetUserAssignedContacts',
  GET_CONTACTS_CHAT_STATUS: 'Chat/GetContactsChatStatus',
  SEND_MESSAGE: 'Chat/SendMessage',
  UPDATE_MESSAGE_STATUS: 'Chat/MarkChatAsRead',
  GET_TEMPLATES: 'AppData/GetTemplates',
  GET_TEMPLATE: 'Chat/GetTemplate',
  UPLOAD_MEDIA: 'Chat/Upload',
  DOWNLOAD_MEDIA: 'Chat/Download',
  GET_CONTACT_CHAT_WINDOW_EXPIRY: 'Chat/GetContactChatWindowExpiry',
  GET_CHAT_HISTORY: 'Chat/GetChatHistory',
};

