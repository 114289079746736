import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  ViewChild,
  ElementRef
} from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { TagDropDownItem } from 'src/app/modules/wharm/contacts/models/tag-model';
import { TagDetail } from '../../models/tag-detail-model';

@Component({
  selector: 'wharm-tags',
  templateUrl: './tags.component.html',
  styleUrls: ['./tags.component.scss'],
})
export class TagsComponent implements ControlValueAccessor, OnChanges {
  public selectValue: any = null;
  public filteredDropDownContent: TagDropDownItem[] = [];
  @Input() public dropDownContent: TagDropDownItem[] = [];
  @Input() public selectedTags: TagDropDownItem[] = [];
  @Input() public placeholder = 'Select Tag';
  @Input() public disabled = false;
  @Input() public noItemsMessage : string = 'No tags to display';
  @Output() public onTagSelect: EventEmitter<TagDetail> = new EventEmitter();
  @ViewChild('tagsContainer') tagsContainer: ElementRef;

  public get value(): any {
    return this.selectValue;
  }

  public set value(value) {
    this.selectValue = value;
    this.onChange(value);
  }

  constructor() {}

  private updateFilterDropDown() {
    this.filteredDropDownContent = this.dropDownContent.filter((content) => {
      return !this.selectedTags.some(
        (selectedTag) => selectedTag.key === content.key
      );
    });
  }

  ngOnChanges() {
    this.updateFilterDropDown();
  }

  public onTouched = () => {};
  public onChange = (_) => {};

  public writeValue(obj: any): void {
    if (obj) {
      this.selectValue = null;
      return;
    }
    if (obj && obj != this.selectValue) {
      this.selectValue = this.dropDownContent?.find(
        (content) => content.key === obj.key
      );
    }
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public onSelect(item: TagDropDownItem) {
    if (!this.selectedTags.some((tag) => tag.key === item.key)) {
      const data: TagDetail = {
        isRemoved: false,
        item,
      };
      this.selectedTags.push(item);
      this.updateFilterDropDown();
      this.onTagSelect.emit(data);
    }
  }

  public removeTag(item: TagDropDownItem) {
    const data: TagDetail = {
      item,
      isRemoved: true,
    };
    const index = this.selectedTags.findIndex(
      (tag) => tag.key === data.item.key
    );
    this.selectedTags.splice(index, 1);
    this.updateFilterDropDown();
    this.onTagSelect.emit(data);
  }

  public handleDDOpen() {
    this.tagsContainer?.nativeElement?.scrollIntoView();
  }
}
