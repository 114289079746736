import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, map, throwError } from 'rxjs';
import { APIResponse } from 'src/app/shared/models/api-response.model';
import { has } from 'lodash';

@Injectable()
export class WharmAPIResponseInterceptor implements HttpInterceptor {

  constructor() { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(request).pipe(map((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse && has(event.body, 'isSuccess') && has(event.body, 'value')) {
        const apiResponse = event.body as APIResponse<any>;
        if (apiResponse.isSuccess) {
          return event.clone({ body: apiResponse.value });
        }
        throw new HttpErrorResponse({
          error: apiResponse.errors,
          headers: event.headers,
          status: apiResponse.statusCode,
          url: event.url
        });
      }
      return event;
    }));
  }
}
