import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InlineSVGModule, TgUikitModule } from '@tg/uikit';
import { RouterModule } from '@angular/router';
import { TemplateDirective } from './directives/template.directive';
import { ChatTimePipe } from './pipes/chat-time.pipe';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { TimerComponent } from './components/chat-conversation-timer/timer.component';
import { TreeComponent } from './components/Tree/tree.component';
import { NodeComponent } from './components/Tree/components/node/node.component';
import { TagsComponent } from './components/tags/tags.component';

@NgModule({
  declarations: [
    TemplateDirective,
    ChatTimePipe,
    TimerComponent,
    TreeComponent,
    NodeComponent,
    TagsComponent,
  ],
  imports: [
    CommonModule,
    TgUikitModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    InlineSVGModule,
    NgxUiLoaderModule
  ],
  exports: [
    CommonModule,
    FormsModule,
    TemplateDirective,
    ChatTimePipe,
    NgxUiLoaderModule,
    TimerComponent,
    TreeComponent,
    TagsComponent
  ],
})
export class SharedModule {}
