import { Injectable } from '@angular/core';
import { cloneDeep } from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { Application, TokenModel, UserModel, UserResponseTokenModel } from 'src/app/shared/models/account-model';
import { StorageService } from 'src/app/utility/StorageService';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  user: UserModel;
  currentApplication: Application;
  userIdentifierToken: string;

  constructor() {
  }

  public init(userData: UserResponseTokenModel) {
    this.user = userData.userDetails;
    this.currentApplication = this.user.applications[0];
    this.userIdentifierToken = userData.userIdentifierToken;
  }

  /**
   * checks if user has provided privilege
   * @param identifier - privilege identifier
   * @returns true if user has privilege
   */
  public hasPrivilege(identifier: string) {
    return this.currentApplication?.applicationRole?.privileges?.some(privilege => identifier === privilege.identifier);
  }

  public hasPrivilegeAny(identifiers: string[]){
    return this.currentApplication?.applicationRole?.privileges?.some(privilege => identifiers?.includes(privilege.identifier));
  }

  public hasPrivilegeAll(identifiers: string[]){
    return this.currentApplication?.applicationRole?.privileges?.every(privilege => identifiers?.includes(privilege.identifier));
  }

}


