import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'chatTime'
})
export class ChatTimePipe implements PipeTransform {

  transform(value: string, isShortHand?: boolean): string {

    if (!value){
      return null;
    }
    let chatTime: string;
    value = moment(value).local().format();
    const time = moment(value).format('LT').toString();


    if (moment().utc().format('L') === (moment.utc(value)).format('L')) {
      return time;
    }
    else if (moment().subtract(1, 'days').utc().format('L') === (moment.utc(value)).format('L')) {
      chatTime = 'Yesterday';
    }
    else {
      chatTime = moment.utc(value).format('DD/MM/YYYY').toString();
    }
    if (isShortHand) {
      chatTime = `${chatTime} ${time}`;
    }
    return chatTime;
  }
}
