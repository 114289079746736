import { PrivilegeTreeModel } from './../modules/wharm/settings/models/roles-and-privileges';
import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Resolve,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { forkJoin, lastValueFrom } from 'rxjs';
import { BFFEndpoints, EndPoints, ServiceKeys } from 'src/app/configs/api';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { HttpClientServiceFactory } from 'src/app/core/services/http/http-client-service-factory.service';
import { IHttpClientService } from 'src/app/core/services/http/http-client.service';
import { Jwt } from 'src/app/core/services/Jwt';
import { UserService } from 'src/app/core/services/user/user.service';
import {
  B2CUserModel,
  TokenModel,
  UserResponseTokenModel,
} from 'src/app/shared/models/account-model';
import { StorageService } from 'src/app/utility/StorageService';
import { DynamicSection } from '../modules/wharm/contacts/models/section.model';
import { TemplateModel } from '../modules/wharm/chats/models/templateModels';
import { AppStateDataService } from '../core/services/app-state-data/app-state-data.service';
import { AppStateData } from '../shared/models/app-state-data/app-state-data';
import { PaginationResponse } from '../shared/models/pagination.model';
import { AppRoleModel } from '../shared/models/app-roles.model';
import { Country } from '../shared/models/meta-data-models';
import { Routes } from '../configs/routes';
import { WharmLoaderService } from '../utility/loader/loader.service';
import { environment } from 'src/environments/environment.devaz';
import { BaseService } from '../shared/services/base-service';
import { WharmToasterService } from '../shared/services/wharm-toaster.service';
import { DynamicPage } from '../modules/wharm/contacts/models/dynamic-page.model';

@Injectable({
  providedIn: 'root',
})
export class WharmModuleResolver implements Resolve<void> {
  private _httpClientBFFService: IHttpClientService;

  constructor(
    httpFactory: HttpClientServiceFactory,
    private authService: AuthenticationService,
    private userService: UserService,
    private appStateDataService: AppStateDataService,
    private _loader: WharmLoaderService
  ) {
    this._httpClientBFFService = httpFactory.createHttpClientService({
      serviceKey: ServiceKeys.BFF,
    });
  }

  private async initUserService() {
    const userDetail = await this.getUser();
    this.userService.init(userDetail);
  }

  private getAppStateData() {
    return new Promise<void>((res) => {
      forkJoin({
        contactDynamicPage: this._httpClientBFFService.getReq<
          DynamicPage[]
        >({ url: BFFEndpoints.GET_APPLICATION_DYNAMIC_CONTACT_FIELDS }),
        chatTemplates: this._httpClientBFFService.getReq<TemplateModel[]>(
          {
            url: BFFEndpoints.GET_TEMPLATES,
          }
        ),
        userRoles: this._httpClientBFFService.postReq<
          PaginationResponse<AppRoleModel>
        >({ url: BFFEndpoints.GET_APP_ROLES, data: { skipPagination: true } }),
        countries: this._httpClientBFFService.getReq<Country[]>({
          url: BFFEndpoints.GET_COUNTRIES,
        }),
        privilegesTree: this._httpClientBFFService.getReq<
          PrivilegeTreeModel[]
        >({ url: BFFEndpoints.GET_APP_PRIVILEGES }),
      }).subscribe((data) => {
        const appData: AppStateData = {
          contact: {
            dynamicDataConfig : data.contactDynamicPage,
          },
          chat: {
            templates: data.chatTemplates,
          },
          user: {
            roles: data.userRoles.data,
          },
          metadata: {
            countries: data.countries,
            rolesAndPrivileges: data.privilegesTree,
          },
        };
        this.appStateDataService.init(appData);
        res();
      });
    });
  }

  private async getUser() {
    const userId = this.authService.UserIdentifier;
    if (!userId) {return null;}
    return await lastValueFrom(
      this._httpClientBFFService.getReq<UserResponseTokenModel>({
        url: `${BFFEndpoints.GET_USER}${userId}`,
      })
    );
  }

  // private getUserId(): string {
  //   let token = StorageService.local.getItem<TokenModel>('accessToken').token;
  // }

  async resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<void> {
    this._loader.start();
    if (!this.authService.isLoggedIn) {
      // if(confirm("unauthorized! Do you want to redirect to login page?"))
      //   this._router.navigate([Routes.Login]);
      return;
    }
    await this.initUserService();
    if(!!this.userService?.currentApplication){
      await this.getAppStateData();
    }
    this._loader.stop();
  }
}
