import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { AuthGuard } from './core/guards/auth.guard';
import { DefaultScreenGuard } from './core/guards/default-screen.guard';
import { WharmModuleResolver } from './resolvers/wharm-module-resolver.service';

const routes: Routes = [
  {
    path: 'account',
    loadChildren: () =>
      import('./modules/account/account.module').then((m) => m.AccountModule),
    canActivate: [DefaultScreenGuard],
    canActivateChild: [DefaultScreenGuard],
  },
  {
    path: 'app',
    loadChildren: () =>
      import('./modules/wharm/wharm.module').then((m) => m.WharmModule),
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    resolve: { data: WharmModuleResolver },
  },
  {
    path: '',
    redirectTo: 'app',
    pathMatch: 'full',
  },
  {
    path: '**',
    redirectTo: 'app',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
