import { PrivilegeTreeModel } from './../../../modules/wharm/settings/models/roles-and-privileges';
import { Injectable } from '@angular/core';
import { cloneDeep } from 'lodash';
import { TemplateModel } from 'src/app/modules/wharm/chats/models/templateModels';
import { AppRoleModel } from 'src/app/shared/models/app-roles.model';
import { AppStateData } from 'src/app/shared/models/app-state-data/app-state-data';
import { Country } from 'src/app/shared/models/meta-data-models';
import { DynamicPage } from 'src/app/modules/wharm/contacts/models/dynamic-page.model';

@Injectable({
  providedIn: 'root'
})
export class AppStateDataService {
  private _contactDynamicDataConfig: DynamicPage[];
  private _chatTemplates: TemplateModel[];
  private _userRoles: AppRoleModel[];
  private _countries: Country[];
  private _appRolePrivileges: PrivilegeTreeModel[];

  constructor() { }

  public init(appStateData: AppStateData) {
    this._contactDynamicDataConfig = appStateData.contact?.dynamicDataConfig;
    this._chatTemplates = appStateData.chat.templates;
    this._userRoles = appStateData.user.roles;
    this._countries = appStateData.metadata.countries;
    this._appRolePrivileges =  appStateData.metadata.rolesAndPrivileges;
  }

  public set UserRoles(roles: AppRoleModel[]) {
    this._userRoles =  roles;
  }

  public get ContactDynamicDataConfig() {
    return cloneDeep(this._contactDynamicDataConfig);
  }

  public get ChatTemplates() {
    return cloneDeep(this._chatTemplates);
  }

  public get UserRoles() {
    return cloneDeep(this._userRoles);
  }

  public get Countries() {
    return cloneDeep(this._countries);
  }

  public get AppRolesPrivilegesTreeData() {
    return cloneDeep(this._appRolePrivileges);
  }

}
