import { Injectable } from '@angular/core';
import * as moment from 'moment';
import {
  BehaviorSubject,
  Observable,
  catchError,
  filter,
  finalize,
  lastValueFrom,
} from 'rxjs';
import { MsalService } from '@azure/msal-angular';
import { IdTokenClaims } from '@azure/msal-common';
import { AuthenticationConstants } from '../constants/literals';
import { msalConfig } from 'src/app/auth-config';
import { environment } from 'src/environments/environment';
import { AuthResponseStatus } from './authResponseStatus';

type IdTokenClaimsWithPolicyId = IdTokenClaims & {
  acr?: string;
  tfp?: string;
};

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  private readinessResolver: (value: void | PromiseLike<void>) => void;
  private authResponseStatusSubj: BehaviorSubject<AuthResponseStatus>;
  private b2cPolicies = environment.azureADB2CConfiguration.b2cPolicies;

  public Ready: Promise<void>;
  public accessToken: string;

  public authResponseStatusObs$: Observable<AuthResponseStatus>;
  public get isLoggedIn() {
    return !!this._msalService.instance.getActiveAccount();
  }

  constructor(private _msalService: MsalService) {
    this.Ready = new Promise<void>((res) => {
      this.readinessResolver = res;
    });
    this.authResponseStatusSubj = new BehaviorSubject<AuthResponseStatus>(null);
    this.authResponseStatusObs$ = this.authResponseStatusSubj
      .asObservable()
      .pipe(filter((s) => !!s));
    this.init();
  }

  private async init() {
    try {
      await this.msalSubscriptionSetup();
      if (this.isLoggedIn) {
        await this.acquireTokenSilently();
      }
    } finally {
      this.readinessResolver();
    }
  }

  private async msalSubscriptionSetup() {
    return new Promise<void>((res, rej) => {
      // Optional - This will enable ACCOUNT_ADDED and ACCOUNT_REMOVED events emitted when a user logs in or out of another tab or window
      this._msalService.instance.enableAccountStorageEvents();
      return this._msalService
        .handleRedirectObservable()
        .pipe(finalize(() => res()))
        .subscribe({
          next: (payload) => {
            if (!!payload) {
              const idtoken = payload.idTokenClaims as IdTokenClaimsWithPolicyId;

              if (
                idtoken.acr?.toLowerCase() ===
                  this.b2cPolicies.names.signUpSignIn?.toLowerCase() ||
                idtoken.tfp.toLowerCase() ===
                  this.b2cPolicies.names.signUpSignIn.toLowerCase()
              ) {
                this._msalService.instance.setActiveAccount(payload.account);
                this.accessToken = payload.idToken;
                this.authResponseStatusSubj.next({ isSuccess: true });
              }
            }
          },
          error: (error) => {
            this.authResponseStatusSubj.next({
              isSuccess: false,
              message: 'Failed to Login!',
            });
            if (!environment.production) {
              //eslint-disable-next-line
              console.log('Auth Error :', error);
            }
          },
        });
    });
  }

  private get isTokenExpired() {
    const activeAccount = this._msalService.instance.getActiveAccount();
    const tokenExpiry = new Date(
      (activeAccount?.idTokenClaims?.exp ?? 0) * 1000
    );
    return activeAccount && moment(tokenExpiry).utc() < moment().utc();
  }

  public get UserIdentifier() {
    const activeAccount = this._msalService.instance.getActiveAccount();
    return activeAccount?.idTokenClaims?.sub;
  }

  public async getAuthToken(): Promise<string> {
    if (!this.isLoggedIn) {
      this.logout();
      return null;
    }

    if (this.isTokenExpired) {
      await this.acquireTokenSilently();
    }

    return this.accessToken;
  }

  public login() {
    this._msalService.loginRedirect({
      scopes: [AuthenticationConstants.openIdScope],
      redirectUri: msalConfig.auth.redirectUri,
    });
  }

  public async acquireTokenSilently(): Promise<void> {
    try {


      const refreshTokenResult = await this._msalService.instance.acquireTokenSilent({
        scopes: [msalConfig.auth.clientId, AuthenticationConstants.openIdScope],
        account: this._msalService.instance.getActiveAccount(),
        forceRefresh: true,
      });

      if (!refreshTokenResult) {
        await this.logout();
      }

      this._msalService.instance.setActiveAccount(refreshTokenResult.account);
      this.accessToken = refreshTokenResult.idToken;
    } catch (error) {
      await this.logout();
    }
  }

  public async logout() {
    return lastValueFrom(this._msalService.logout());
  }
}
