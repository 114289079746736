import { PrivilegeModel } from './privilege.model';

export interface UserCredentials {
  email: string;
  password: string;
  token: string;
}

export class ActivateAccountQueryParams {
  token: string;
  email: string;
}

export interface InitAccountActivationModel {
  email: string;
  name: string;
}

export interface AccountRegisterResponseModel {
  valueOrDefault: number;
  value: number;
  isFailed: boolean;
  isSuccess: boolean;
  reasons: any;
  errors: any;
  successes: any;
}

export interface LoginModel {
  email: string;
  password: string;
}

export class AccountQueryParams {
  token: string;
  email: string;
}
export class ForgotPasswordEmail {
  email: string;
}

export interface AccountModel {
  email: string;
  password: string;
  confirmPassword: string;
}

export interface ChangePasswordModel {
  email: string;
  currentPassword: string;
  newPassword: string;
}
export interface ChangePasswordFormModel {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
}

export interface ChangeEmailModel {
  oldEmail: string;
  newEmail: string;
  name: string;
}

export interface VerifyEmailChangeModel {
  oldEmail: string;
  newEmail: string;
  token: string;
}

export interface GetAccessTokenModel {
  accessToken: string;
  refreshToken: string;
}

export interface Login {
  email: string;
  password: string;
}

export interface LoginResponseModel {
  accessToken: TokenModel;
  refreshToken: TokenModel;
}
export interface TokenModel {
  token: string;
  expiresAt: Date;
}

export interface UserModel {
  id: number;
  userIdentifier: string | null;
  fullName: string;
  firstName: string;
  lastName: string;
  email: string;
  accessStartDate: string;
  accessEndDate: string | null;
  applications?: Application[];
  isActive: boolean;
  createdBy: number | null;
  updatedBy: number | null;
  createdDate: string | null;
  updatedDate: string | null;
}

export interface UserResponseTokenModel {
  userDetails: UserModel;
  userIdentifierToken: string;
}

export interface Application {
  applicationId: number;
  applicationName: string;
  applicationUserId: number;
  applicationRole?: ApplicationRoleModel;
  whatsappPhoneAccounts: WhatsappPhoneAccount[];
}

export interface ApplicationRoleModel {
  id: number;
  applicationRoleName: string;
  privileges?: PrivilegeModel[];
}

export interface WhatsappPhoneAccount {
  id: number;
  name: string;
}

export interface ErrorModel {
  errorCode: string;
  errorId: string;
  isErrorVisible: boolean;
  message: string;
  metadata: Metadata;
  reasons: any[];
}

export interface Metadata {}

export interface B2CUserModel {
  aud: string;
  authTime: number;
  emails: string[];
  exp: number;
  givenName: string;
  iat: number;
  idp: string;
  iss: string;
  name: string;
  nbf: number;
  nonce: string;
  oid: string;
  sub: string;
  tfp: string;
  ver: string;
}
