import { Injectable } from '@angular/core';
import { AuthenticationService } from './core/services/authentication.service';
import { WharmLoaderService } from './utility/loader/loader.service';

@Injectable()
export class AppInitService {
  constructor(private _authService: AuthenticationService) {}

  async Init() {
    await this._authService.Ready;
  }
}
