export const X_CSRF_TOKEN = 'X_CSRF_TOKEN';

export const AuthenticationConstants = {
  accessToken: 'accessToken',
  expirationDateProp: 'exp',
  openIdScope: 'openid',
};

export const Literals = {
  discardChanges: `Do you want to discard the changes?`,
  deleteConfirmation: `Are you sure you want to delete the changes?`,
};
