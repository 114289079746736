export interface TreeModel<T> {
  name: string;
  description?: string;
  nodes: NodeModel<T>[];
  metadata: TreeMetaData;
}

export interface TreeMetaData {
  isDirty: boolean;
}

export interface NodeModel<T> {
  id: number;
  name: string;
  description?: string;
  checkStatus?: ECheckStatus;
  nodes?: NodeModel<T>[];
  data?: T;
}

export enum ECheckStatus {
  Checked = 1,
  PartiallyChecked = 2,
  UnChecked = 3,
}
