import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NodeModel, ECheckStatus, TreeMetaData} from 'src/app/shared/models/tree-model';

@Component({
  selector: 'wharm-node',
  templateUrl: './node.component.html',
  styleUrls: ['./node.component.scss']
})
export class NodeComponent<T> implements OnInit {
  @Input() node: NodeModel<T>;
  @Input() treeMetaData: TreeMetaData;
  @Output() onChildNodeCheckStatusChange: EventEmitter<NodeModel<T>> = new EventEmitter<NodeModel<T>>();

  public eCheckStatus = ECheckStatus;
  public endNodes:NodeModel<T>[];
  public childrenNodes:NodeModel<T>[];

  constructor() { }

  private toggleNodeChildrens(parentNode: NodeModel<T>, status: boolean) {
    if(!parentNode) {
      return;
    }
    if(!!parentNode.nodes) {
      parentNode.nodes.forEach(node => {
        node.checkStatus = status ? ECheckStatus.Checked : ECheckStatus.UnChecked;
        this.toggleNodeChildrens(node, status);
      });
    }
  }

  ngOnInit(): void {
    if(!this.node) {
      return;
    }
    this.childrenNodes = this.node.nodes?.filter(s=>s.nodes?.length > 0);
    this.endNodes = this.node.nodes?.filter(s=>s.nodes?.length == 0);
  }


  public toggleNode(event, node: NodeModel<T>) {
    if(!node) {
      return;
    }
    if(!this.treeMetaData.isDirty) {
      this.treeMetaData.isDirty = true;
    }
    const status = event.target.checked;
    node.checkStatus = status ? ECheckStatus.Checked : ECheckStatus.UnChecked;
    if(node?.nodes?.length>0){
      this.toggleNodeChildrens(node, status);
    }
    if(this.node === node){
      this.onChildNodeCheckStatusChange.emit(node);
    }
    else{
      this.onChildNodeCheckStatusChangeFunc(node);
    }
  }


  public onChildNodeCheckStatusChangeFunc(childNode:NodeModel<T>){
    if(childNode && this.node?.checkStatus !== childNode.checkStatus){
      if(this.node.nodes?.every(s=>s.checkStatus == ECheckStatus.Checked)){
        this.node.checkStatus = ECheckStatus.Checked;
      }else if(this.node.nodes?.every(s=>s.checkStatus == ECheckStatus.UnChecked)){
        this.node.checkStatus = ECheckStatus.UnChecked;
      }else{
        this.node.checkStatus = ECheckStatus.PartiallyChecked;
      }
      this.onChildNodeCheckStatusChange.emit(this.node);
    }
  }

}
