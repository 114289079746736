import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { from, lastValueFrom, Observable } from 'rxjs';
import { BFFEndpoints, EndPoints } from 'src/app/configs/api';
import { AuthenticationService } from '../services/authentication.service';
import { UserService } from '../services/user/user.service';

//TODO: see better way to design user-service.
//TODO: move this to module level
@Injectable()
export class TokenInterceptorService implements HttpInterceptor {
  constructor(
    private _userService: UserService,
    private _authenticationService: AuthenticationService
  ) {}
  private whiteListUrls = [
    EndPoints.ACCOUNT_ACTIVATION,
    EndPoints.LOGIN,
    EndPoints.LOGOUT,
    EndPoints.REFRESH_TOKEN,
    EndPoints.FORGOT_PASSWORD,
    EndPoints.INITIATE_FORGOT_PASSWORD,
    EndPoints.VERIFY_EMAIL_CHANGE,
  ];
  
  private async handle(req: HttpRequest<any>, next: HttpHandler) {
    const slug = req.url.split('api/')[1];
    if (!this.whiteListUrls.includes(slug)) {
      const authToken = await this._authenticationService.getAuthToken();

      let headers = req.headers;
      headers = headers.set('Authorization', `Bearer ${authToken}`);

      if (!slug.includes(BFFEndpoints.GET_USER)) {
        headers = headers.set('user-identifier-token', this._userService?.userIdentifierToken);
      }

      req = req.clone({
        headers: headers,
      });
    }

    return await lastValueFrom(next.handle(req));
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return from(this.handle(req, next));
  }
}
