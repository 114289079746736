export const environment = {
  production: true,

  serviceConfig: [
    {
      key: 'BFF',
      baseURL: 'https://dev.wharm-bff.techigai.net',
    }
  ],
  toastConfig: {
    success: {
      timeDuration: 3,
    },
    warning: {
      timeDuration: 3,
    },
    error: {
      timeDuration: 3,
    },
  },
  signalRUrl: 'https://dev.wharm-notification.techigai.net/alerts',
  typeAheadDebounceTime: 300,
  chatConfig: {
    maxFileSize: 10485760,
    maxFileNameLength: 50,
    supportedTextMessageLength: 4096,
    supportedCaptionMessageLength: 1024,
  },
  ngxUiLoaderConfig: {
    master: {
      blur: 5,
      delay: 0,
      fastFadeOut: true,
      fgsColor: 'white',
      fgsPosition: 'center-center',
      fgsSize: 80,
      fgsType: 'rectangle-bounce',
      gap: 24,
      masterLoaderId: 'master',
      hasProgressBar: false,
      text: 'loading...',
      textColor: '#FFFFFF',
      textPosition: 'center-center',
      pbColor: 'var(--primary)',
      pbThickness: 3
    }
  },
  chatConversationTimer: {
    limitInMinutes: 24 * 60, // setting to 24hr in minutes; need to configure accd to whatsapp conversation window time limit
    progressBarZonesLimit: {
      warning: {
        percentage:25,
        color: '#EBA501'
      },
      danger: {
        percentage: 12.5,
        color: '#FF5F5F'
      }
    }
  },
  azureADB2CConfiguration: {
    b2cPolicies: {
      names: {
        signUpSignIn: 'B2C_1_wharm_dev_signin_signup',
        resetPassword: 'B2C_1_reset_v3',
        editProfile: 'B2C_1_edit_profile_v2',
      },
      authorities: {
        signUpSignIn: {
          authority: 'https://wharm.b2clogin.com/wharm.onmicrosoft.com/b2c_1_wharm_dev_signin_signup',
        },
        resetPassword: {
          authority: 'https://wharm.b2clogin.com/wharm.onmicrosoft.com/B2C_1_reset_v3',
        },
        editProfile: {
          authority: 'https://wharm.b2clogin.com/wharm.onmicrosoft.com/b2c_1_edit_profile_v2',
        },
      },
      authorityDomain: 'wharm.b2clogin.com',
    },
    isDirectLogIn: false,
    clientId: '5ad744cf-7474-4ef5-82cb-d98c2abd6f14',
    redirectUri: 'https://dev.wharm.techigai.net/',
    loginButtonLabel: 'Login with Microsoft',
  }
};
