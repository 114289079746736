import { Injectable } from '@angular/core';
import { AppToast, ToastsService } from '@tg/uikit';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class WharmToasterService {
  constructor(private toastsService: ToastsService) {}

  successMessage(title: string, message: string) {
    this.toastsService.addToast(
      new AppToast({
        title: title,
        text: message,
        level: 'success',
        options: { timeout: environment.toastConfig.success.timeDuration },
      })
    );
  }

  warningMessage(title: string, message: string) {
    this.toastsService.addToast(
      new AppToast({
        title: title,
        text: message,
        level: 'warning',
        options: { timeout: environment.toastConfig.warning.timeDuration },
      })
    );
  }

  errorMessage(title: string, message: string) {
    this.toastsService.addToast(
      new AppToast({
        title: title,
        text: message,
        level: 'error',
        options: { timeout: environment.toastConfig.error.timeDuration },
      })
    );
  }

}
