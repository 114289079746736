import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { InlineSVGModule, TgUikitModule } from '@tg/uikit';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { AuthGuard } from './core/guards/auth.guard';
import { CoreModule } from './core/core.module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptorService } from './core/interceptors/http.interceptor.service';
import { SharedModule } from './shared/shared.module';
import { NgxUiLoaderConfig, NgxUiLoaderModule } from 'ngx-ui-loader';
import { environment } from 'src/environments/environment';
import {
  MSAL_INSTANCE,
  MsalBroadcastService,
  MsalModule,
  MsalService,
} from '@azure/msal-angular';
import {
  IPublicClientApplication,
  PublicClientApplication,
} from '@azure/msal-browser';
import { msalConfig } from './auth-config';
import { AppInitService } from './app.initializer';
import { WharmAPIResponseInterceptor } from './wharm.api-response.interceptor';

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication(msalConfig);
}

export function initializeApp(appInitService: AppInitService) {
  return (): Promise<any> => {
    return appInitService.Init();
  };
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    TgUikitModule.forRoot(),
    InlineSVGModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    CoreModule,
    SharedModule,
    NgxUiLoaderModule.forRoot(
      environment.ngxUiLoaderConfig.master as NgxUiLoaderConfig
    ),
    MsalModule,
  ],
  providers: [
    AppInitService,
    MsalService,
    AuthGuard,
    MsalBroadcastService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: WharmAPIResponseInterceptor,
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    { provide: APP_INITIALIZER, useFactory: initializeApp, deps: [AppInitService], multi: true }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
