import { Component, Input } from '@angular/core';
import { ECheckStatus, NodeModel, TreeModel } from '../../models/tree-model';
@Component({
  selector: 'wharm-tree',
  templateUrl: './tree.component.html',
  styleUrls: ['./tree.component.scss']
})
export class TreeComponent<T> {
  @Input() tree: TreeModel<T>;

  constructor() { }

  public onRootNodeCheckStatusChangeFunc(childNode:NodeModel<T>) {
    const node = this.tree?.nodes.find(s=>s?.nodes.some(s=>s.id == childNode?.id));
    if(node && childNode && node.checkStatus !== childNode.checkStatus){
      if(node.nodes?.every(s=>s.checkStatus == ECheckStatus.Checked)){
        node.checkStatus = ECheckStatus.Checked;
      }else if(node.nodes?.every(s=>s.checkStatus == ECheckStatus.UnChecked)){
        node.checkStatus = ECheckStatus.UnChecked;
      }else{
        node.checkStatus = ECheckStatus.PartiallyChecked;
      }
    }
    if(!this.tree.metadata.isDirty) {
      this.tree.metadata.isDirty = true;
    }
  }

}
