<span *ngIf="!showCircularProgress && ((hours+minutes) > 0)" class="timer">{{ hours | number: '2.0'}}:{{ minutes |
    number: '2.0'}} left
    <img src="/assets/icons/timer.svg" *ngIf="showTimerIcon">
</span>

<div *ngIf="showCircularProgress && ((hours+minutes) > 0)" class="circularTimer">
    <svg>
        <circle cx="22" cy="22" r="20"></circle>
        <circle cx="22" cy="22" r="20" stroke-dasharray="125" [ngStyle]="{ 
            'stroke-dashoffset' : calculateTimerOffset(125),
            'stroke': progressBarColor
        }">
        </circle>
    </svg>
    <span class="time" [ngStyle]="{'color': progressBarColor}">
        {{ hours | number: '2.0'}}:{{ minutes | number: '2.0'}}
    </span>
</div>