import { Directive, Input, TemplateRef } from '@angular/core';

@Directive({
  selector: '[templateId]'
})
export class TemplateDirective {
    // @Input() type: 'header' | 'column';
    @Input() templateId: string;
    constructor(public templateRef: TemplateRef<any>) { }
}
